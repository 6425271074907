const PATH = {
  auth: {
    forgotPassword: '/auth/forgotpassword',
    signIn: '/auth/signin',
    signUp: '/auth/signup',
    register: '/auth/register',
    becomeMember: '/auth/becomeMember',
  },
  registration: '/registration',
  lei: '/lei',
  eCert: '/eCert',
  creditReport: '/creditReport',
  profile: {
    edit: '/profile/edit',
    accountEdit: '/profile/accountEdit',
    supplementalInfo: '/profile/supplementalInfo',
    identificationCheck: '/profile/identificationCheck',
    eCert: '/profile/eCert',
    creditReport: '/profile/creditReport',
    companyinfo: '/profile/companyinfo',
    personalinfo: '/profile/personalinfo',
    resetPassword: '/profile/resetPassword',
    comments: '/profile/comments',
    addNewMembers: '/profile/addNewMembers',
    editMembers: '/profile/addNewMembers/edit/:userId',
    subscriptionDetail: '/profile/subscriptionDetail',
  },
  home: {
    index: '/home',
    notifications: '/home/notifications',
  },
  deal: {
    index: '/deal',
    initiation: '/deal/initiation',
    executionEdit: '/deal/:id/excution/edit',
    notificationDetails: '/deal/:id/details',
    detail: {
      index: 'deal/:id',
      manageParticipants: 'deal/:id/participants',
      manageFiles: 'deal/:id/files',
      contractSigning: '/deal/:id/contractSigning/:fileId',
      createMeeting: '/deal/:id/createMeeting',
    },
  },
  event: {
    index: '/event',
    newEvent: '/event/newEvent',
    joinMeeting: '/event/joinMeeting',
  },
  meetingRedirect: '/meeting/redirect',
  guestYellowPage: {
    index: '/:lang/eDirectory',
    detail: '/:lang/eDirectory/:id',
    comments: '/:lang/eDirectory/:id/comments',
  },
  yellowPage: {
    index: '/eDirectory',
    detail: '/eDirectory/:id',
    comments: '/eDirectory/:id/comments',
  },
  guestLawFirmsList: {
    index: '/:lang/professionalService',
    detail: '/:lang/professionalService/:id',
    comments: '/:lang/professionalService/:id/comments',
  },
  lawFirmsList: {
    index: '/professionalService',
    detail: '/professionalService/:id',
    comments: '/professionalService/:id/comments',
  },
  guestComapnyDetail: {
    index: '/:lang/companyDetail/:id',
  },
  comapnyDetail: {
    index: '/companyDetail/:id',
    comments: '/companyDetail/:id/comments',
  },
  features: {
    list: '/:lang/features',
    detail: '/:lang/features/:id',
  },
  blog: {
    list: '/:lang/blog',
    detail: '/:lang/blog/:cus_title',
  },
  faq: {
    list: '/:lang/faq',
    detail: '/:lang/faq/:id',
  },
  services: '/:lang/services',
  subscription: '/:lang/Subscription',
  about: '/:lang/About',
  translate: '/translate',
  contractExpamples: '/contractTemplate',
  payment: {
    index: '/payment',
    detail: '/payment/:id',
  },
  docLibrary: '/docLibrary',
  eSignature: '/eSignature',
  meeting: '/meeting',
  cart: {
    index: '/cart',
    checkout: '/cart/checkout',
    checkoutSuccess: '/cart/success',
    cancel: '/cart/cancel',
    checkoutCreditReport: '/cart/success/credit-report'
  },
  chatRoom: '/chat',
  eSign: {
    uploadBankCard: '/eSign/myfacesign/uploadBankCard',
    customGetHWSign: '/eSign/myfacesign/customGetHWSign',
  },
  companyCreditReport: '/companyCreditReport',
  LEISearch: '/LEISearch',
  GuestSign: {
    success: '/success_sign',
    fail: '/fail_sign',
  },
  guest: {
    creditReport: '/:lang/guest/creditReport',
    getCreditReport: '/:lang/guest/getCreditReport',
  },
  testMCE: '/testMCE',
};

export default PATH;