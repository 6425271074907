import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../Footer';
import { ContainerWrapper, Title, CardWrapper, CardsList } from './style';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Link } from 'react-router-dom';
import useResize from '@/hooks/useResize';
import { getBlog } from '@/api/frontData.api';
import PATH from '@/router/path';
import { LoadingPage } from '@/layout';
import { sify } from 'chinese-conv/dist'
import { errorHandler } from '@/utils/toastHandler';
import { Helmet } from 'react-helmet';

const Blog = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const { isTablet, isMobile } = useResize();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Blog[]>([]);

  const initData = async () => {
    setIsLoading(true);
    try {
      const res = (await getBlog()).data;

      // 按照weight排序, 0為最優先, 若weight相同則按照created_at排序
      const sortedBlog = res.sort((a, b) => {
        return b.weight - a.weight;
      });
      setData(sortedBlog);
    } catch (error) {
      console.log(error);
      errorHandler(t('error.server'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    initData();
  }, [lang]);

  return (
    <>
      <Helmet>
        <title>{t('seo.features.title')}</title>
        <meta itemProp="name" content={t('seo.features.title')} />
        <meta property="og:title" content={t('seo.features.title')} />
        <meta name="twitter:title" content={t('seo.features.title')} />
        <meta name="description" content={t('seo.features.metaDescription')} />
        <meta property="og:description" content={t('seo.features.metaDescription')} />
        <meta name="twitter:description" content={t('seo.features.metaDescription')} />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/en/features`} />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/en/features`} hrefLang="en" />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/sc/features`} hrefLang="zh-Hant" />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/tc/features`} hrefLang="zh-Hans" />
      </Helmet>
      <LoadingPage isLoading={isLoading} />
      <ContainerWrapper>
        <Title>{t('pages.index.Blog.title')}</Title>
        <CardsList isTablet={isTablet} isMobile={isMobile}>
          {data.map((blog) => (
            <CardWrapper key={blog.title + blog.title_en} isTablet={isTablet} isMobile={isMobile}>
              <div className="pic">
                <img src={blog.img_url} alt="" />
              </div>
              <div className="content">
                <h2>{lang === 'en' ? blog.title_en : lang === 'tc' ? blog.title : sify(blog.title)}</h2>
                <p>{lang === 'en' ? blog.description_en : lang === 'tc' ? blog.description : sify(blog.description)}</p>
                <Link to={PATH.blog.detail.replace(':lang', lang).replace(':id', blog.cus_title)}>{t('buttons.howtoUse')}</Link>
              </div>
            </CardWrapper>
          ))}
        </CardsList>
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default Blog;
