import { useState, useEffect, useMemo, useCallback } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import DataTable, { FileMenu, TypeColumn } from '@/components/DataTable';
import { Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import Loading from '@/components/UI/Loading';
import { Text } from '@/components/UI/Typography/Text';
import { theme } from '@/theme/Theme';
import { convertLink } from '@/utils/convert';
import { getReportByAnonymousEmail } from '@/api/creditReport.api';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const Information = ({ setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const rawColumn = t('pages.guest.creditReport.steps.step3.table', { returnObjects: true });
  const navigate = useNavigate();
  const convertTime = (time: string) => new Date(time).toLocaleString();

  const prevHandler = () => {
    navigate('/');
  };
  const [reportData, setReportData] = useState<CreditReportResponse[]>([]);

  const downloadHandler = (url: string) => {
    if (!url) return;
    // if (data?.file) {
    //   window.open(convertLink(data.file.path), '_blank');
    //   return;
    // }
    window.open(url, '_blank');
    return;
  };

  const columns: ColumnDef<CreditReportResponse>[] = useMemo(
    () => [
      {
        accessorKey: 'Company Name',
        header: rawColumn[0],
        sortable: true,
        cell: ({ row }) => <TypeColumn text={row.original.orgName} />,
      },
      {
        accessorKey: 'Pruchased Data',
        header: rawColumn[1],
        cell: ({ row }) => <TypeColumn text={convertTime(row.original.created_at)} />,
      },
      {
        accessorKey: 'action',
        header: rawColumn[2],
        cell: ({ row }) => (
          <Button
            size={'table'}
            type={'button'}
            variant={'primary'}
            rounded={false}
            onClick={() => downloadHandler(row.original.pdfUrl)}
          >
            {t('buttons.download')}
          </Button>
        ),
      },
    ],
    [rawColumn, t]
  );

  const getAnonReport = useCallback(async (email: string, token: string) => {
    try {
      const rawData = {
        anonymousEmail: email!,
        token: token!,
      };

      const res = (await getReportByAnonymousEmail(rawData)).data;
      if (res.status) {
        setReportData(res.reports);
      }
    } catch (error) { }
  }, []);

  useEffect(() => {
    // 進來頁面時檢查網址是否有帶email和token參數, 若無則檢查localStorage是否有email和token
    const params = new URLSearchParams(window.location.search);
    let email = params.get('mail');
    let token = params.get('token');

    if (!email) {
      email = localStorage.getItem('anonymousEmail');
    }

    if (!token) {
      token = localStorage.getItem('token');
    }

    if (email && token) {
      getAnonReport(email, token);
    }
  }, []);
  return (
    <Wrapper>
      <Text>
        The email has been sent to your inbox. You can go to the Company Credit Report download page again through the link in the email.
      </Text>

      <Box style={{ paddingTop: 22 }}>
        <Link to={'/companyCreditReport'}>
          <Button size={'lg'} type="button" variant={'primary'} rounded={false} marginRight={40}>
            {t('buttons.viewPurchasedCR')}
          </Button>
        </Link>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Box>
      {reportData ? <DataTable columns={columns} data={reportData} /> : <Loading />}
    </Wrapper>
  );
};

export default Information;
