import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, LoadingPage } from '@/layout';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { applyCreditReport, checkCompanyName } from '@/api/creditReport.api';
import { Button } from '@/components/UI/Button';
import { FormSelect } from '@/components/UI/Select';
import { Title } from '@/components/UI/Typography/Text';
import { errorHandler } from '@/utils/toastHandler';
import { H5 } from '@/components/UI/Typography/Heading';
import { theme } from '@/theme/Theme';
import { ConfirmModal, ErrorModal, PurchaseModal, TermsModal } from '../Modal';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import usePermissionCheck from '@/hooks/usePermissionCheck';

interface FormStepperProps {
  nextHandler: () => void;
  prevHandler: () => void;
  setData: React.Dispatch<React.SetStateAction<CreditReportResponse | undefined>>;
}

const OtherCompanies = ({ nextHandler, prevHandler, setData }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [agree, setAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const { userData } = useAppSelector(getAuthSelector);
  const [count, setCount] = useState<number>(0);
  const { isMainAccount } = usePermissionCheck();
  const queryTypePlaceholder = t('form.queryType.placeholder', { returnObjects: true });

  const handleAgree = () => {
    setAgree(!agree);
  };

  const {
    setValue,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { isDirty },
  } = useForm<CreditReportApplicationForm>();

  const typeOptions = t('pages.eTrust.creditReport.steps.step2.queryTypes', { returnObjects: true });

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setShowConfirmModal(false);
    setShowPurchaseModal(false);
    setShowTermsModal(false);

    if (data.queryType === '0') {
      data.companyId = '';
    } else if (data.queryType === '2') {
      data.companyName = '';
    }

    try {
      
      await checkCompanyName({ companyName: data.companyName });

      const res = (await applyCreditReport(data)).data;

      if (res.status) {
        setData(res.report);
        nextHandler();
      }
    } catch (error: any) {
      setError('companyName', { type: 'manual', message: t('pages.eTrust.creditReport.steps.step2.modal.error') });
      setShowErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    setValue('queryType', '0');
    setValue('name', `${userData.acc_firstName} ${userData.acc_lastName}`);
    setCount(userData.user_feature ? userData.user_feature.ESignatureDigitalCertTimes : 0);
  }, [t]);

  const modalHandler = () => {
    if (!isMainAccount) {
      errorHandler(t('error.permission'));
      return;
    }

    if (count === 0) {
      setShowPurchaseModal(true);
      return;
    } else {
      setShowTermsModal(true);
      return;
    }
  };

  const modalSwitch = () => {
    setShowTermsModal(false);
    setShowConfirmModal(true);
  };

  return (
    <form onSubmit={onSubmit}>
      <LoadingPage isLoading={isLoading} />
      <ConfirmModal count={count} onSubmit={onSubmit} showModal={showConfirmModal} setShowModal={setShowConfirmModal} />
      <PurchaseModal showModal={showPurchaseModal} setShowModal={setShowPurchaseModal} />
      <ErrorModal showModal={showErrorModal} setShowModal={setShowErrorModal} />
      <TermsModal onSubmit={modalSwitch} showModal={showTermsModal} setShowModal={setShowTermsModal} />

      <Box marginBottom={60}>
        <Title marginBottom={15}>{t('pages.eTrust.creditReport.steps.step2.otherComapnies.applicantInfo')}</Title>
        <InputWrapper style={{ height: 'auto' }}>
          <Controller
            control={control}
            name={'name'}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label={t('form.companyRepresentative.label') + ' *'}
                variant="standard"
                value={value}
                sx={{ width: '100%', marginRight: '15px' }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                autoComplete="off"
                inputProps={{
                  readOnly: true,
                }}
                defaultValue={`${userData.acc_firstName} ${userData.acc_lastName}`}
              />
            )}
            rules={{ required: t('required.input', { input: t('form.companyRepresentative.label') }) }}
          />
        </InputWrapper>
      </Box>

      <Box>
        <Title marginBottom={15}>{t('pages.eTrust.creditReport.steps.step2.otherComapnies.searchedCompanies')}</Title>
        <InputWrapper>
          <FormSelect control={control} data={typeOptions} inputLabel={''} selectLabel={''} name={'queryType'} />
        </InputWrapper>

        <InputWrapper style={{ height: 'auto' }}>
          {watch('queryType') === '2' && (
            <Controller
              control={control}
              name={'companyId'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={''}
                  placeholder={queryTypePlaceholder[Number(watch('queryType'))]}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={{
                required: t('required.feild'),
                maxLength: {
                  value: 18,
                  message: `${queryTypePlaceholder[Number(watch('queryType'))]}`,
                },
                minLength: {
                  value: 18,
                  message: `${queryTypePlaceholder[Number(watch('queryType'))]}`,
                },
              }}
            />
          )}

          {watch('queryType') === '0' && (
            <Controller
              control={control}
              name={'companyName'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={''}
                  placeholder={queryTypePlaceholder[Number(watch('queryType'))]}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={{
                required: t('required.feild'),
              }}
            />
          )}
        </InputWrapper>

        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: '#999999',
                '&.Mui-checked': {
                  color: '#999999',
                },
              }}
              checked={agree}
              onChange={handleAgree}
            />
          }
          label={t('form.agreePlatformUseInfo.label')}
        />
      </Box>

      <Box marginTop={30}>
        <div>{t('pages.eTrust.creditReport.steps.step1.useCR')}</div>
        <H5 marginTop={15} color={theme.colors.primary500}>
          {t('pages.eTrust.creditReport.steps.step1.remainCR', {
            count,
          })}
        </H5>
      </Box>

      <Flex marginTop={50}>
        <Button
          size={'lg'}
          type="button"
          variant={'primary'}
          rounded={false}
          marginRight={40}
          disabled={!agree || !isDirty}
          onClick={modalHandler}
        >
          {t('buttons.submit')}
        </Button>
        <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
          {t('buttons.back')}
        </Button>
      </Flex>
    </form>
  );
};

export default OtherCompanies;
