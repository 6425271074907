import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';

import PageContainer from '@/components/Container/PageContainer';
import SearchBar from './SearchBar';
import List from './List';

import { getYellowPageSearch } from '@/api/auth.api';
import CNSearchBar from './CNSearchBar';
import { LoadingPage } from '@/layout';
import useCurrentLang from '@/hooks/useCurrentLang';

const YellowPage = ({ type = 'YellowPage' }: { type: 'YellowPage' | 'LawFirmsList' }) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentLang = useCurrentLang();
  const [showData, setShowData] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');

  const [resData, setResData] = useState<any>();
  const [recommendData, setRecommendData] = useState<any>();

  useEffect(() => {
    if (type === 'YellowPage') {
      searchParams.get('page') === null && navigate('/'+currentLang+'/eDirectory?page=1&per_page=10');
    } else if (type === 'LawFirmsList') {
      searchParams.get('page') === null && navigate('/'+currentLang+'/professionalService?page=1&per_page=10');
    }

    getData();
    // if (searchParams.get('page') !== null) {
    // } else {
    //   getRecommendData();
    // }
  }, [location.search, t]);

  const getData = async () => {
    if (searchParams.get('page') === null) return;
    const currentPage = searchParams.get('page');
    const currentPerPage = searchParams.get('per_page');
    if (currentPage) {
      setPage(parseInt(currentPage));
    }
    if (currentPerPage) {
      setPerPage(parseInt(currentPerPage));
    }

    if (type === 'YellowPage') {
      try {
        setIsLoading(true);
        const res: YellowPageResponse = (await getYellowPageSearch(location.search)).data;
        if (res.success) {
          setResData(res.data);
          setTotalData(res.data.total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    } else if (type === 'LawFirmsList') {

      // 檢查queryString中是否有出現category, 若無則加上'&category=Legal Services,Accounting', 若有則在參數加上',China'
      let queryString = location.search;
      if (!queryString.includes('category')) {
        queryString += '&category=Legal Services,Accounting';
      }

      try {
        setIsLoading(true);
        const res: YellowPageResponse = (await getYellowPageSearch(queryString)).data;
        if (res.success) {
          setResData(res.data);
          setTotalData(res.data.total);
        }
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getRecommendData = async () => {
    // 初次進入的預設值放入網址列
    searchParams.set('page', '1');
    searchParams.set('per_page', '10');
    try {
      const res = (await getYellowPageSearch(`?per_page=${perPage}&page=${page}`)).data;

      if (res.success) {
        setRecommendData(res.data);
      }
    } catch (error) { }
  };

  return (
    <>
      {
        type === 'YellowPage' ? (
          <Helmet>
            <title>{t('seo.yellowPage.title')}</title>
            <meta itemProp="name" content={t('seo.yellowPage.title')} />
            <meta property="og:title" content={t('seo.yellowPage.title')} />
            <meta name="twitter:title" content={t('seo.yellowPage.title')} />
            <meta name="description" content={t('seo.yellowPage.metaDescription')} />
            <meta property="og:description" content={t('seo.yellowPage.metaDescription')} />
            <meta name="twitter:description" content={t('seo.yellowPage.metaDescription')} />
            <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/en/eDirectory`} />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/en/eDirectory`} hrefLang="en" />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/sc/eDirectory`} hrefLang="zh-Hant" />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/tc/eDirectory`} hrefLang="zh-Hans" />
          </Helmet>
        ) : (
          <Helmet>
            <title>{t('seo.lawFirmsList.title')}</title>
            <meta itemProp="name" content={t('seo.lawFirmsList.title')} />
            <meta property="og:title" content={t('seo.lawFirmsList.title')} />
            <meta name="twitter:title" content={t('seo.lawFirmsList.title')} />
            <meta name="description" content={t('seo.lawFirmsList.metaDescription')} />
            <meta property="og:description" content={t('seo.lawFirmsList.metaDescription')} />
            <meta name="twitter:description" content={t('seo.lawFirmsList.metaDescription')} />
            <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/en/professionalService`} />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/en/professionalService`} hrefLang="en" />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/sc/professionalService`} hrefLang="zh-Hant" />
            <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/tc/professionalService`} hrefLang="zh-Hans" />
          </Helmet>
        )
      }
      <PageContainer title={'e-Directory'}>
        <LoadingPage isLoading={isLoading} />
        {type === 'YellowPage' ? <SearchBar /> : <CNSearchBar setCurrentCategory={setCurrentCategory} />}
        <List
          showData={showData}
          data={resData?.data}
          recommendData={recommendData?.data}
          setPage={setPage}
          totalData={totalData}
          perPage={perPage}
          page={page}
          lastPage={resData?.last_page}
          total={resData?.total}
          type={type}
          currentCategory={currentCategory}
        />
      </PageContainer>
    </>
  );
};

export default YellowPage;
