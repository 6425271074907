import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ContainerWrapper,
  FooterWrapper,
  Navbar,
  Anchor,
  MediaList,
  MediaLink,
  LeftWrapper,
  RightWrapper,
} from './style';
import useResize from '@/hooks/useResize';
import useGetFeatures from '@/hooks/useGetFeatures';
import useCurrentLang from '@/hooks/useCurrentLang';
import { Box } from '@/layout';
import { Mail, Phone, Pin } from '@/components/Icon';
import { theme } from '@/theme/Theme';
import { sify } from 'chinese-conv/dist';

interface FooterLink {
  title: string;
  link: { name: string; url: string }[];
}

const Footer = () => {
  return <DesktopFooter />;
};

const DesktopFooter = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const currentLang = useCurrentLang();
  const { isMobile, isTablet } = useResize();
  const footerLinks = t('pages.footer', { returnObjects: true });
  const features = useGetFeatures();
  const [showFooter, setShowFooter] = useState(false);

  const [data, setData] = useState<FooterLink[]>([]);

  const socialMedia = [
    // {
    //   icon: '/images/socialMedia/facebook.svg',
    //   link: 'https://www.facebook.com/eBRAM.ODR',
    // },
    {
      icon: '/images/socialMedia/linkedin.svg',
      link: 'https://www.linkedin.com/company/ebram-odr/',
    },
  ];

  const initData = () => {
    if (features.length === 0) return;
    // 將features陣列的資料按照weight排序, 0為最優先, 若weight相同則按照created_at排序
    const sortedFeatures = features.sort((a, b) => {
      if (a.weight === b.weight) {
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
      }
      return b.weight - a.weight;
    });

    // 將排序後的features透過map轉換成和{name: string; url: string}[]格式
    const portalFeaturesLinks = sortedFeatures.map((item) => {
      if (!item.cus_title_tc) {
        item.cus_title_tc = '';
      }
      return (
        {
          name: currentLang === 'en' ? item.cus_title : currentLang === 'tc' ? item.cus_title_tc : sify(item.cus_title_tc),
          url: `/features/${item.cus_title}`,
        }
      )
    });

    // 找出title為Portal Features的footerLinks, 並將其替換成portalFeaturesLinks
    const newFooterLinks = footerLinks.map((item) => {
      if (item.title === 'Portal Features') {
        return { ...item, link: portalFeaturesLinks };
      }
      return item;
    })

    // 將所有的footerLinks中的link網址加上語系前綴
    // 除了Resources的link中name為Privacy Policy和Terms of Use的link
    // 這兩個link的網址不需要加上語系前綴
    newFooterLinks.forEach((item) => {
      item.link.forEach((link) => {
        if (link.name === 'Privacy Policy' || link.name === 'Terms of Use') return;
        link.url = `/${currentLang}${link.url}`;
      });
    });

    // 存入data
    setData(newFooterLinks);
    setShowFooter(true);
  }

  useEffect(() => {
    setShowFooter(false);
    initData();
  }, [features, t]);

  return (
    <ContainerWrapper>
      <FooterWrapper isTablet={isTablet} isMobile={isMobile}>
        <LeftWrapper isTablet={isTablet} isMobile={isMobile}>
          <div className="title">
            {t('pages.index.Contact.title')}
          </div>
          <div className="infoBox">
            <Box width={'24px'}>
              <Pin.PinSVG />
            </Box>
            <div>{t('pages.index.footer.address')}</div>
          </div>
          <div className="infoBox">
            <Mail.MailSVG />
            <a href={`mailto:${t('pages.index.footer.email')}`}>{t('pages.index.footer.email')}</a>
          </div>
          <div className="infoBox">
            <Phone.PhoneSVG />
            <div>{t('pages.index.footer.phone')}</div>
          </div>
          <MediaList isTablet={isTablet} isMobile={isMobile}>
            {socialMedia.map((item, index) => (
              <MediaLink key={item.link + index} target="_blank" href={item.link}>
                <img width={'100%'} src={item.icon} alt="icon" />
              </MediaLink>
            ))}
          </MediaList>
        </LeftWrapper>
        <RightWrapper isTablet={isTablet} isMobile={isMobile}>
          {
            showFooter && (
              <Navbar isTablet={isTablet} isMobile={isMobile}>
                {data.map((footer, index) => (
                  <div key={footer.title + index}>
                    <div className="title">{footer.title}</div>
                    {footer.link.map((target, index) => (
                      <Link key={target.name + index} to={target.url}>
                        <Anchor>{target.name}</Anchor>
                      </Link>
                    ))}
                  </div>
                ))}
              </Navbar>
            )
          }
        </RightWrapper>
      </FooterWrapper>
      <Box padding={12} textAlign={'center'} backgroundColor={theme.colors.primary500} color={theme.colors.light}>{t('pages.index.footer.copyright')}</Box>
    </ContainerWrapper>
  );
};

export default Footer;
