import { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptInvite, rejectInvite } from '@/api/deal.api';

import { Box, Card, Flex } from '@/layout';
import { Text } from '@/components/UI/Typography/Text';

import { Hr } from './styles';
import { theme } from '@/theme/Theme';
import { Link, useNavigate } from 'react-router-dom';
import StyledLink from '@/components/UI/Link';
import { Button } from '@/components/UI/Button';
import { successHandler } from '@/utils/toastHandler';

import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import styled from 'styled-components';
import { convertCategory, convertToCurrentLangData } from '@/utils/convert';
import useCurrentLang from '@/hooks/useCurrentLang';
import { dealType } from '@/data/select';

interface DealInfoProps {
  dealDetail: DealDetail;
  dealOwner?: boolean;
  haveGuest: boolean;
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'unset',
    maxWidth: 'unset',
    fontSize: theme.fontSizes.base,
    whiteSpace: 'nowrap',
  },
}));

const DealInfo = ({ haveGuest, dealDetail, dealOwner = false }: DealInfoProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const lang = useCurrentLang();
  const navigate = useNavigate();
  const [showDes, setShowDes] = useState(false);
  const [dealTypeOptions, setDealTypeOptions] = useState<SelectOptions>();
  const dealRoleArray = t('dealRoleArray', { returnObjects: true });
  const [inviter, setInviter] = useState(dealDetail.participants[0]);

  const isInviting = dealDetail.progress === '0' ? true : false;

  const buyer = useMemo(
    () => dealDetail.participants.find((participants) => participants.deal_role_id === 0)?.user,
    [dealDetail]
  );

  const seller = useMemo(
    () => dealDetail.participants.find((participant) => participant.deal_role_id === 1)?.user,
    [dealDetail]
  );

  const expiryDate = useMemo(() => {
    const date = new Date(dealDetail.expiry_date);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}/${month >= 10 ? month : '0' + month}/${day}`;
  }, [dealDetail]);

  const isExpired = useMemo(() => {
    const date = new Date(dealDetail.expiry_date);
    const now = new Date();
    return date < now;
  }, [dealDetail]);

  const responseInvite = async (userRes: boolean) => {
    if (userRes) {
      try {
        const res = (await acceptInvite(dealDetail.id)).data;
        successHandler(t('success.dealAccept'));
        navigate('/deal');
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = (await rejectInvite(dealDetail.id)).data;
        successHandler(t('success.dealReject'));
        navigate('/deal');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getDealTypeOptions = useCallback(async () => {
    const dealTypeAry = await dealType(lang);
    setDealTypeOptions(dealTypeAry);
  }, [lang]);

  useEffect(() => {
    getDealTypeOptions();
  }, [getDealTypeOptions]);

  return (
    <Card display={'flex'} flexDirection={'column'} gridGap={'15px'}>
      <Flex gridGap={10}>
        {inviter.deal_role_id === 0 ? (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][0].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              {haveGuest ? (
                <CustomTooltip title={buyer?.acc_coname} placement="top">
                  <Text
                    color={theme.colors.primary500}
                    marginBottom={10}
                    style={{
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 100,
                    }}
                  >
                    {buyer && buyer?.acc_coname}
                  </Text>
                </CustomTooltip>
              ) : (
                <Link to={buyer ? `/companyDetail/${buyer.id}` : '#'}>
                  <CustomTooltip title={buyer?.acc_coname} placement="top">
                    <Text
                      color={theme.colors.primary500}
                      marginBottom={10}
                      style={{
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 100,
                      }}
                    >
                      {buyer && buyer?.acc_coname}
                    </Text>
                  </CustomTooltip>
                </Link>
              )}
              <div>{buyer && convertToCurrentLangData(buyer.acc_country, 'name', lang)}</div>
            </Box>
          </Flex>
        ) : dealDetail.progress !== '0' ? (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][0].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              {haveGuest ? (
                <CustomTooltip title={buyer?.acc_coname} placement="top">
                  <Text
                    color={theme.colors.primary500}
                    marginBottom={10}
                    style={{
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 100,
                    }}
                  >
                    {buyer && buyer?.acc_coname}
                  </Text>
                </CustomTooltip>
              ) : (
                <Link to={buyer ? `/companyDetail/${buyer.id}` : '#'}>
                  <CustomTooltip title={buyer?.acc_coname} placement="top">
                    <Text
                      color={theme.colors.primary500}
                      marginBottom={10}
                      style={{
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 100,
                      }}
                    >
                      {buyer && buyer?.acc_coname}
                    </Text>
                  </CustomTooltip>
                </Link>
              )}
              <div>{buyer && convertToCurrentLangData(buyer.acc_country, 'name', lang)}</div>
            </Box>
          </Flex>
        ) : (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][0].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              <Text color={theme.colors.primary500} marginBottom={10}>
                {t('buttons.inviting')}
              </Text>
              <div>-</div>
            </Box>
          </Flex>
        )}
        <Hr />
        {inviter.deal_role_id === 1 ? (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][1].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              {haveGuest ? (
                <CustomTooltip title={seller?.acc_coname} placement="top">
                  <Text
                    color={theme.colors.primary500}
                    marginBottom={10}
                    style={{
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 100,
                    }}
                  >
                    {seller && seller?.acc_coname}
                  </Text>
                </CustomTooltip>
              ) : (
                <Link to={seller ? `/companyDetail/${seller.id}` : '#'}>
                  <CustomTooltip title={seller?.acc_coname} placement="top">
                    <Text
                      color={theme.colors.primary500}
                      marginBottom={10}
                      style={{
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 100,
                      }}
                    >
                      {seller && seller?.acc_coname}
                    </Text>
                  </CustomTooltip>
                </Link>
              )}
              <div>{seller && convertToCurrentLangData(seller.acc_country, 'name', lang)}</div>
            </Box>
          </Flex>
        ) : dealDetail.progress !== '0' ? (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][1].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              {haveGuest ? (
                <CustomTooltip title={seller?.acc_coname} placement="top">
                  <Text
                    color={theme.colors.primary500}
                    marginBottom={10}
                    style={{
                      textDecoration: 'underline',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 100,
                    }}
                  >
                    {seller && seller?.acc_coname}
                  </Text>
                </CustomTooltip>
              ) : (
                <Link to={seller ? `/companyDetail/${seller.id}` : '#'}>
                  <CustomTooltip title={seller?.acc_coname} placement="top">
                    <Text
                      color={theme.colors.primary500}
                      marginBottom={10}
                      style={{
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 100,
                      }}
                    >
                      {seller && seller?.acc_coname}
                    </Text>
                  </CustomTooltip>
                </Link>
              )}
              <div>{seller && convertToCurrentLangData(seller.acc_country, 'name', lang)}</div>
            </Box>
          </Flex>
        ) : (
          <Flex gridGap={10}>
            <Box>
              <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={10}>
                {dealRoleArray[Number(dealDetail.deal_type_id) - 1][1].text}
              </Text>
              <Text color={theme.colors.gray300}>{t('info.location')}</Text>
            </Box>
            <Box>
              <Text color={theme.colors.primary500} marginBottom={10}>
                {t('buttons.inviting')}
              </Text>
              <div>-</div>
            </Box>
          </Flex>
        )}
        <Hr />
        <Flex flexDirection={'column'} gridGap={10}>
          <Flex>
            <Text color={theme.colors.primary500} fontWeight={'900'} style={{ width: 130 }}>
              {t('dealType')}
            </Text>
            <CustomTooltip
              title={dealTypeOptions && dealTypeOptions[parseInt(dealDetail.deal_type.id) - 1].text}
              placement="top"
            >
              <Text
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 100,
                }}
              >
                {dealTypeOptions && dealTypeOptions[parseInt(dealDetail.deal_type.id) - 1].text}
              </Text>
            </CustomTooltip>
          </Flex>
          <Flex>
            <Text color={theme.colors.primary500} fontWeight={'900'} style={{ width: 130 }}>
              {t('category')}
            </Text>
            <CustomTooltip
              title={
                convertCategory(dealDetail.category, lang) +
                `${dealDetail.category_other ? ` (${dealDetail.category_other})` : ''}`
              }
              placement="top"
            >
              <Text style={{ maxWidth: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {convertCategory(dealDetail.category, lang) +
                  `${dealDetail.category_other ? ` (${dealDetail.category_other})` : ''}`}
              </Text>
            </CustomTooltip>
          </Flex>
        </Flex>
        <Hr />
        <Flex flexDirection={'column'} gridGap={10}>
          <Flex gridGap={10} justifyContent={'space-between'}>
            <Text color={theme.colors.primary500} fontWeight={'900'}>
              {t('dealExpiryDate')}
            </Text>
            <CustomTooltip title={expiryDate} placement="top">
              <Text>{expiryDate}</Text>
            </CustomTooltip>
          </Flex>
          <Flex gridGap={10} justifyContent={'space-between'}>
            <Text color={theme.colors.primary500} fontWeight={'900'}>
              {t('info.currency')}
            </Text>
            <CustomTooltip
              title={
                convertToCurrentLangData(dealDetail.currency, 'currency', lang) +
                `${dealDetail.currency_other ? ` (${dealDetail.currency_other})` : ''}`
              }
              placement="top"
            >
              <Text style={{ maxWidth: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {convertToCurrentLangData(dealDetail.currency, 'currency', lang) +
                  `${dealDetail.currency_other ? ` (${dealDetail.currency_other})` : ''}`}
              </Text>
            </CustomTooltip>
          </Flex>
          <Flex gridGap={10} justifyContent={'space-between'}>
            <Text color={theme.colors.primary500} fontWeight={'900'}>
              {t('info.languages')}
            </Text>{' '}
            <CustomTooltip
              title={
                convertToCurrentLangData(dealDetail.languages, 'language', lang) +
                `${dealDetail.languages_other ? ` (${dealDetail.languages_other})` : ''}`
              }
              placement="top"
            >
              <Text style={{ maxWidth: 220, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {convertToCurrentLangData(dealDetail.languages, 'language', lang) +
                  `${dealDetail.languages_other ? ` (${dealDetail.languages_other})` : ''}`}
              </Text>
            </CustomTooltip>
          </Flex>
        </Flex>
      </Flex>
      <div>
        <Text color={theme.colors.primary500} fontWeight={'900'} marginBottom={'10px'}>
          {t('description')}
        </Text>
        <Text>
          {dealDetail.description.length > 120 && !showDes
            ? dealDetail.description.substring(0, 120) + '...'
            : dealDetail.description}
          {dealDetail.description.length > 120 && !showDes && (
            <StyledLink to={'#'} onClick={() => setShowDes(true)}>
              {t('buttons.more')}
            </StyledLink>
          )}
        </Text>
      </div>
      {isInviting && !dealOwner && !isExpired && (
        <Flex marginTop={30} marginLeft={'auto'} gridGap={20} alignItems={'flex-end'}>
          <Text fontSize={theme.fontSizes.sm} color={theme.colors.red}>
            ({`${t('pages.deal.dealExpiry')} ${expiryDate}`})
          </Text>
          <Flex gridGap={15}>
            <Button onClick={() => responseInvite(true)} variant={'primary'}>
              {t('buttons.accept')}
            </Button>
            <Button onClick={() => responseInvite(false)} variant={'primary'}>
              {t('buttons.reject')}
            </Button>
          </Flex>
        </Flex>
      )}
      {isInviting && isExpired && (
        <Flex marginTop={30} marginLeft={'auto'} gridGap={20} alignItems={'flex-end'}>
          <Text fontSize={theme.fontSizes.sm} color={theme.colors.red}>
            {`${t('pages.deal.expiriedOn')} ${expiryDate}`}
          </Text>
        </Flex>
      )}
    </Card>
  );
};

export default DealInfo;
