import { axios } from '@/utils/axios';

/**
 * ANCHOR 取得列表
 */
export const getCompanyCreditReports = async () => {
  return axios.get('/api/creditReport/companyCreditReports');
};

/**
 * ANCHOR 發送自身creditReport請求
 */
export const applySelfCreditReport = async (data: Omit<CreditReportApplicationForm, 'companyName, queryType, companyId'>) => {
  return axios.post('/api/creditReport/applySelfCreditReport', data);
};

/**
 * ANCHOR 發送其他公司的creditReport請求
 */
export const applyCreditReport = async (data: CreditReportApplicationForm) => {
  return axios.post('/api/creditReport/applyCreditReport', data);
};

/** 
 * ANCHOR 未登入者購買信用報告
*/
export const applyCreditReportForAnonymous = async (data: ApplyCreditReportForAnonymous) => {
  return axios.post('/api/creditReport/applyCreditReportForAnonymous', data);
};

/**
 * ANCHOR 匿名者取德信用報告
 */
export const getReportByAnonymousEmail = async (data: {anonymousEmail:string, token: string}) => {
  return axios.post('/api/creditReport/getReportByAnonymousEmail', data);
};

/**
 *  ANCHOR 發送信用報告前的驗證
 * 
 */
export const checkCompanyName = async (data: { companyName: string }) => {
  return axios.post('/api/creditReport/checkCompanyName', data);
}