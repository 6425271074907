import { useState, useEffect } from 'react';
import { Step, StepLabel, Stepper } from '@mui/material';

interface StepLineProps {
  activeStep: number;
  stepline: string[];
}

const Stepline = ({ activeStep, stepline }: StepLineProps) => {
  const [line, setLine] = useState(stepline);

  useEffect(() => setLine(stepline), [stepline]);

  return (
    <Stepper
      activeStep={activeStep}
      orientation="vertical"
      style={{ paddingRight: '20px', height: 'min-content', position: 'relative', minWidth: '200px' }}
    >
      {line.map((item: string) => (
        <Step key={item}>
          <StepLabel>{item}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};
export default Stepline;
