import React, { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mui/material';
import { Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import { Text } from '@/components/UI/Typography/Text';
import { ModalForm } from './style';

interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
}

const CheckoutModal = ({ showModal, setShowModal, onSubmit }: ModalProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableEnforceFocus
    >
      <ModalForm>
        <Text>
       {t('pages.guest.creditReport.steps.step2.modal.title')}
        </Text>

        <Flex marginTop={50} gridGap={15} justifyContent={'center'}>
          <Button type="button" size={'lg'} variant={'primary'} onClick={() => {
            setShowModal(false);
            onSubmit();
          }}>
            {t('buttons.proceedToPayment')}
          </Button>
          <Button type="button" size={'lg'} onClick={() => setShowModal(false)}>
            {t('buttons.cancel')}
          </Button>
        </Flex>
      </ModalForm>
    </Modal>
  );
};

export { CheckoutModal };
