import React, { useEffect } from 'react';
import { useParams, Outlet, useNavigate, useLocation } from 'react-router-dom';
import useCurrentLang from '@/hooks/useCurrentLang';
import { useTranslation } from 'react-i18next';

const LangWrapper = () => {
  // @ts-ignore
  const { i18n } = useTranslation();

  const currentLang = useCurrentLang();
  const { lang } = useParams<{ lang: string }>();
  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {
    // 如果lang沒有值, 則跳轉至當前的語系
    // 例如: /en, /sc, /tc
    // 若lang有值, 則確認是否在支援的語系內, 若不在同樣跳轉至當前的語系的首頁
    if (!lang) {
      navigate(`/${i18n.language}`, { replace: true });
      return;
    } else if (!['sc', 'tc', 'en'].includes(lang)) {
      // 如果lang不在支援的語系內, 則跳轉至當前的語系的首頁
      navigate(`/${i18n.language}`);
      return;
    }
    i18n.changeLanguage(lang);
  }, [lang, currentLang, location.pathname, navigate]);

  return <Outlet />;
};

export default LangWrapper;