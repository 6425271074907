import { axios } from '@/utils/axios';
import filenameValidator from '@/utils/filenameValidator';

/**
 * ANCHOR 取得檔案列表
 */
export const getDocument = async () => {
  return axios.get('/api/document');
};

/**
 * ANCHOR 上傳檔案
 */
export const storeDocument = async (data: LibraryDataRequest) => {
  const formData = new FormData();
  for (const [index, iterator] of Object.entries(data.files)) {
    formData.append(`files[${index}]`, iterator, filenameValidator(iterator));
  }

  return axios.post('/api/document', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

/**
 * ANCHOR 更新檔案
 */
export const updateDocument = async (id: number, data: []) => {
  return axios.put(`/api/document/${id}`, data);
};

/**
 * ANCHOR 刪除檔案
 */
export const DestroyDocument = async (id: string) => {
  return axios.delete(`/api/document/${id}`);
};

/**
 * ANCHOR Translate
 */
export const Translate = async (data: {
  document_id: string;
  languagePairId: string;
  from: string;
  to: string;
}) => {
  return axios.post(`/api/document/translate`, data);
};

/**
 * ANCHOR Transcribe
 */
export const Transcribe = async (data: { document_id: string; locale: string }) => {
  return axios.post(`/api/document/transcribe`, data);
};

/**
 * ANCHOR GetLanguagePair
 */
export const GetLanguagePair = async () => {
  return axios.get(`/api/document/getLanguagePair`);
}