import { useEffect } from 'react';
import Footer from './Footer';
import { useTranslation } from 'react-i18next';
import Banner from './components/Banner';
import { GoTop } from './style';
import useResize from '@/hooks/useResize';
import { Box } from '@/layout';
import StrategicPartners from './StrategicPartners';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector, switchLayout } from '@/redux/auth/auth.slice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const Index = () => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  const { isTablet, isMobile } = useResize();
  const auth = useAppSelector(getAuthSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // 檢測是否為guest帳號, 若login為true且accessToken為空, 則將login設為false
    if (!auth.isLogin) return;
    dispatch(switchLayout(false));
  }, [auth, dispatch]);

  // 檢測location.pathname, 若為'/'則跳轉至當前語系的首頁
  useEffect(() => {
    const currentPath = location.pathname;
    const lang = currentPath.split('/')[1];
    const isValidLang = ['sc', 'tc', 'en'].includes(lang);
    if (isValidLang) {
      const newPath = currentPath.replace(/^\/(sc|tc|en)/, `/${lang}`);
      navigate(newPath, { replace: true });
    } else {
      // 如果lang不在支援的語系內, 則跳轉至正確的語系的頁面
      navigate(`/${i18n.language}`, { replace: true });
    }
  }, []);

  return (
    <div>
      <Box position={'relative'} zIndex={0}>
        <Banner />
        <StrategicPartners />
        <Footer />
        <GoTop
          isTablet={isTablet}
          isMobile={isMobile}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {t('top')}
        </GoTop>
      </Box>
    </div>
  );
};

export default Index;
