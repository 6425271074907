import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';
import Rating from '@/components/Rating';
import { useLocation, useParams } from 'react-router-dom';

const CompanyComments = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const [prevLink, setPrevLink] = useState('');

  useEffect(() => {
    if (location.pathname.replace('/comments', '') !== '0') {
      setPrevLink(location.pathname.replace('/comments', ''));
    }
  }, []);

  return (
    <PageContainer title={<InnerPageTitle link={prevLink} subtitle={t('comments')} />}>
      {id && <Rating user_id={parseInt(id)} />}
    </PageContainer>
  );
};

export default CompanyComments;
