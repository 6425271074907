import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabWrapper, Wrapper } from '../styles';
import { Box, Flex } from '@/layout';
import { Button } from '@/components/UI/Button';
import OwnCompany from './OwnCompany';

interface FormStepperProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const RegistrationForm = ({ setCurrentStep, setActiveStep }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [seatchParmas, setSearchParams] = useSearchParams();

  const prevHandler = () => {
    setCurrentStep('1');
    setActiveStep(0);
    setSearchParams({ step: '1' });
  };

  const nextHandler = () => {
    setCurrentStep('3');
    setActiveStep(2);
    setSearchParams({ step: '3' });
  };

  return (
    <TabWrapper>
      <OwnCompany nextHandler={nextHandler} prevHandler={prevHandler} />
    </TabWrapper>
  );
};

export default RegistrationForm;
