import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, LoadingPage } from '@/layout';
import { InputWrapper } from '@/components/Container/AuthContainer/styles';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { applyCreditReportForAnonymous, checkCompanyName } from '@/api/creditReport.api';
import { Button } from '@/components/UI/Button';
import { H4 } from '@/components/UI/Typography/Heading';
import { CheckoutModal } from '../Modal';
import { theme } from '@/theme/Theme';
import useAuthValidation from '@/hooks/requireSchema/useAuthValidation';
import { Link } from 'react-router-dom';
import PATH from '@/router/path';

interface FormStepperProps {
  nextHandler: () => void;
  prevHandler: () => void;
}

const OwnCompany = ({ nextHandler, prevHandler }: FormStepperProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const [agree, setAgree] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false);
  const { emailRegister } = useAuthValidation();
  const formRef = useRef<HTMLFormElement>(null);

  const [checkDetail, setCheckDetail] = useState<CheckoutResponse>();

  // 若checkDetail更新則刷新畫面
  useEffect(() => {
    if (!checkDetail) return;
    formRef.current?.submit();
  }, [checkDetail]);

  const handleAgree = () => {
    setAgree(!agree);
  };

  // CreditReportApplicationForm要額外新增email欄位
  const {
    handleSubmit,
    control,
    setError,
    formState: { isDirty },
  } = useForm<ApplyCreditReportForAnonymous>();

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    setShowCheckoutModal(false);
    try {

      // 檢查公司名稱是否存在
      await checkCompanyName({ companyName: data.companyName });

      const res = (await applyCreditReportForAnonymous(data)).data;
      // 成功則將email和token記錄到localStorage, 等待結帳完畢後的重新導向後重新取得匿名者email
      localStorage.setItem('anonymousEmail', data.anonymousEmail);
      localStorage.setItem('token', res.data.token);

      setCheckDetail(res.data as CheckoutResponse);
    } catch (error) {
      setError('companyName', { type: 'manual', message: t('pages.eTrust.creditReport.steps.step2.modal.error') });
      setIsLoading(false);
      return;
    }
  });

  const modalHanlder = () => {
    setShowCheckoutModal(true);
    return;
  };

  useEffect(() => { }, [t]);

  return (
    <>
      <div hidden>
        <form ref={formRef} action={process.env.REACT_APP_CART_URL} method="post">
          <div id="paymentDetailsSection" className="section">
            <input type="text" value={checkDetail ? checkDetail.access_key : ''} name="access_key" />
            <input type="text" value={checkDetail ? checkDetail.profile_id : ''} name="profile_id" />
            <input type="text" value={checkDetail ? checkDetail.transaction_uuid : ''} name="transaction_uuid" />
            <input type="text" value={checkDetail ? checkDetail.signed_field_names : ''} name="signed_field_names" />
            <input
              type="text"
              value={checkDetail ? checkDetail.unsigned_field_names : ''}
              name="unsigned_field_names"
            />
            <input type="text" value={checkDetail ? checkDetail.signed_date_time : ''} name="signed_date_time" />
            <input type="text" value={checkDetail ? checkDetail.locale : ''} name="locale" />
            <input type="text" value={checkDetail ? checkDetail.transaction_type : ''} name="transaction_type" />
            <input type="text" value={checkDetail ? checkDetail.reference_number : ''} name="reference_number" />
            <input type="number" value={checkDetail ? checkDetail.amount : ''} name="amount" />
            <input type="text" value={checkDetail ? checkDetail.currency : ''} name="currency" />
            <input type="text" value={checkDetail ? checkDetail.signature : ''} name="signature" />
          </div>
        </form>
      </div>
      <form>
        <CheckoutModal showModal={showCheckoutModal} setShowModal={setShowCheckoutModal} onSubmit={onSubmit} />
        <LoadingPage isLoading={isLoading} />
        <Box>
          <H4 style={{ color: theme.colors.primary500, fontWeight: 'bold' }}>{t('pages.guest.creditReport.steps.step2.customerName')}</H4>
          <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'anonymousName'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.companyRepresentative.label') + '*'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  autoComplete="off"
                />
              )}
              rules={{ required: t('required.input', { input: t('form.companyRepresentative.label') }) }}
            />
          </InputWrapper>
          <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'anonymousEmail'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.email.label') + '*'}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : t('pages.guest.creditReport.steps.step2.emailPlaceholder')}
                  autoComplete="off"
                />
              )}
              rules={emailRegister}
            />
          </InputWrapper>
        </Box>

        <Box marginTop={50} marginBottom={30}>
          <H4 style={{ color: theme.colors.primary500, fontWeight: 'bold' }}>{t('pages.guest.creditReport.steps.step2.creditCheckInfo')}</H4>
          <InputWrapper style={{ height: 'auto' }}>
            <Controller
              control={control}
              name={'companyName'}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  label={t('form.targetedCompanyName.label')}
                  variant="standard"
                  value={value}
                  sx={{ width: '100%', marginRight: '15px' }}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : t('pages.guest.creditReport.steps.step2.companyNamePlaceholder')}
                  autoComplete="off"
                />
              )}
              rules={{ required: t('required.input', { input: t('info.companyName') }) }}
            />
          </InputWrapper>

          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: '#999999',
                  '&.Mui-checked': {
                    color: '#999999',
                  },
                }}
                checked={agree}
                onChange={handleAgree}
              />
            }
            label={t('pages.guest.creditReport.steps.step2.agreement')}
          />

          <Flex marginTop={20}>
            <H4 style={{ color: theme.colors.primary500, fontWeight: 'bold' }}>{t('pages.guest.creditReport.steps.step2.fee')}：</H4>
            <H4 style={{ color: theme.colors.secondary500, fontWeight: 'bold' }}>{t('pages.payment.currency.HKD')} 4,000 / 1 {t('pages.guest.creditReport.steps.step2.serving')}</H4>
          </Flex>

        </Box>

        <Flex marginTop={20}>
          <Button
            size={'lg'}
            type="button"
            variant={'primary'}
            rounded={false}
            marginRight={40}
            disabled={!isDirty || !agree}
            onClick={modalHanlder}
          >
            {t('buttons.buyNow')}
          </Button>
          <Button size={'lg'} type="button" variant="primary-invert" marginRight={40} onClick={prevHandler}>
            {t('buttons.back')}
          </Button>
        </Flex>

        <Flex marginTop={20}>
          <Link to={PATH.auth.signUp}>
            <H4 style={{ color: theme.colors.secondary500, fontWeight: 'bold', textDecoration: 'underline' }}>{t('pages.guest.creditReport.steps.step2.register')}</H4>
          </Link>
        </Flex>
      </form>
    </>

  );
};

export default OwnCompany;
