import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAuthSelector } from '@/redux/auth/auth.slice';
import { useAppSelector } from '@/redux/store.hook';
import gradeGenerator from '@/utils/gradeGenerator';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import {
  UserInfoBadge,
  SupplementalInfoBadge,
  IdentificationCheckBadge,
  ECertBadge,
  CreditReportBadge,
  AccountVerifiedBadge,
} from '@/components/UI/Badge';
import PageContainer from '@/components/Container/PageContainer';
import InnerPageTitle from '@/components/UI/InnerPageTitle';

import { IconBtn } from '../style';
import { Main, InformationWrapper, ProfileWrapper, Hr, DetailWrapper, InfoTitle } from './style';

import { getYellowPageDetail } from '@/api/auth.api';
import { generateChatToUser } from '@/api/chat.api';
import { Flex, LoadingPage } from '@/layout';
import { convertLink, convertCategory, convertToCurrentLangData, convertTitle } from '@/utils/convert';
import { errorHandler } from '@/utils/toastHandler';
import useCurrentLang from '@/hooks/useCurrentLang';
import PATH from '@/router/path';
import useResize from '@/hooks/useResize';
import { Button } from '@/components/UI/Button';
import { theme } from '@/theme/Theme';

const YellowPageDetail = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const { id } = useParams();
  const location = useLocation();

  const [tab, setTab] = useState<boolean>(true);
  const [showData, setShowData] = useState<boolean>(false);
  const [data, setData] = useState<MemberProfile>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showVerifiedBadge, setShowVerifiedBadge] = useState(false);
  const [prevLink, setPrevLink] = useState('');

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!id) return;
    // if (location.pathname.includes('/professionalService') && location.pathname.replace('/professionalService/', '') !== '0') {
    //   getData(id!);
    //   setShowData(true);
    // } else {
    //   if (location.pathname.replace('/eDirectory/', '') !== '0') {
    //     getData(location.pathname.replace('/eDirectory/', ''));
    //     setShowData(true);
    //   }
    // }

    getData(id!);
    setShowData(true);
  }, []);

  const getData = async (id: string) => {
    try {
      setIsLoading(true);
      const res = (await getYellowPageDetail({ id: id })).data;
      if (res.success) {
        setData(res.data);
        checkShowVerifiedBadge(res.data);
      }
    } catch (error: any) {
      if (error.response.status === 404) {
        errorHandler(error.response.data.data[0]);
        if (location.pathname.includes('/professionalService')) {
          navigate('/professionalService');
        } else {
          navigate('/eDirectory');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  // 確認是否顯示徽章
  const checkShowVerifiedBadge = (data: MemberProfile) => {
    // 有子母公司的情況需子公司與母公司皆有驗證才將驗證badge顯示
    if (data.verification.accountVerified && data.childCompanies) {
      const childVerified = data.childCompanies.every((item) => item.verification.accountVerified);
      if (childVerified) {
        setShowVerifiedBadge(true);
      }
      return;
    }

    // 梅子公司的情況只需母公司有驗證就將驗證badge顯示
    if (data.verification.accountVerified) {
      setShowVerifiedBadge(true);
    }
  };

  return (
    <PageContainer
      title={
        <InnerPageTitle
          link={prevLink}
          subtitle={
            <Flex gridGap={'5px'}>
              {data?.acc_coname}
              {showVerifiedBadge && (
                <Flex>
                  <AccountVerifiedBadge width={24} align={'center'} />
                </Flex>
              )}
            </Flex>
          }
        />
      }
    >
      <LoadingPage isLoading={isLoading} />
      {showData && data && (
        <Main isTablet={isTablet} isMobile={isMobile}>
          {isTablet ? (
            <>
              <Flex
                gridGap={'10px'}
                margin={'0 auto 30px'}
                padding={isMobile ? '0 15px' : '0'}
                width={'100%'}
                maxWidth={400}
                justifyContent={'center'}
              >
                <Button
                  onClick={() => setTab(!tab)}
                  type={'button'}
                  size={'half'}
                  variant={tab ? 'regular' : 'normal-invert'}
                >
                  {t('info.information')}
                </Button>
                <Button
                  onClick={() => setTab(!tab)}
                  type={'button'}
                  size={'half'}
                  variant={!tab ? 'regular' : 'normal-invert'}
                >
                  {t('info.profile')}
                </Button>
              </Flex>
              {tab ? <Information data={data} /> : <Profile data={data} showVerifiedBadge={showVerifiedBadge} />}
            </>
          ) : (
            <>
              <Information data={data} />
              <Profile data={data} showVerifiedBadge={showVerifiedBadge} />
            </>
          )}
        </Main>
      )}
    </PageContainer>
  );
};

interface InformationProps {
  data: MemberProfile;
}

const Information = ({ data }: InformationProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const lang = useCurrentLang();

  const checkWebsiteLink = (link: string) => {
    if (link.includes('http://') || link.includes('https://')) {
      return link;
    } else {
      return 'http://' + link;
    }
  };

  const convertOptions = (options: string[]) => {
    function convertSector() {
      const sectorOptions = t('mapOptions.lawFirmSectorSpecialty', { returnObjects: true });
      // 檢查傳入的options是否有在sectorOptions的value裡面
      return options.map((option) => {
        const find = sectorOptions.find((item) => item.value === option);
        return find ? find.text : option;
      });
    }

    function convertPractice() {
      const practiceOptions = t('mapOptions.practiceAreas', { returnObjects: true });
      return options.map((option) => {
        const find = practiceOptions.find((item) => item.value === option);
        return find ? find.text : option;
      });
    }

    return { sector: convertSector().join(', '), practice: convertPractice().join(', ') };
  };

  return (
    <InformationWrapper isMobile={isMobile} isTablet={isTablet}>
      <InfoTitle>{t('companyRepresentative')}</InfoTitle>
      <Flex flexDirection={'column'} gridGap={'15px'}>
        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.companyLogo')}</div>
          <div className="info">
            <img width={'100%'} height={100} style={{ objectFit: 'contain' }} src={data.acc_logo ?? '/images/default_company_logo.jpg'} alt="" />
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.website')}</div>
          <div className="info">
            {data.acc_website ? (
              <a
                href={checkWebsiteLink(data.acc_website)}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline', color: 'blue' }}
              >
                {data.acc_website}
              </a>
            ) : (
              '-'
            )}
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.companyEmail')}</div>
          <div className="info">
            {data.com_email ? (
              <a
                href={`mailto: ${data.com_email}`}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline', color: 'blue' }}
              >
                {data.com_email}
              </a>
            ) : (
              '-'
            )}
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.telephone')}</div>
          <div className="info">{`(${data.acc_MobileCountryCode}) ${data.acc_tel}`}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.fax')}</div>
          <div className="info">{data.acc_fax ? `(${data.acc_MobileCountryCode}) ${data.acc_fax}` : '-'}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.companyAddress')}</div>
          <div className="info">{data.acc_address?.split('@@_@@').join(' ')}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.companyDescription')}</div>
          <div className="info">{data.acc_desc}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.natureOfBusiness')}</div>
          <div className="info">
            {data.acc_category
              ? `${convertCategory(data.acc_category, lang)} ${
                  data.acc_category_other ? `(${data.acc_category_other})` : ''
                }`
              : '-'}
          </div>
        </DetailWrapper>

        {data.acc_category?.includes('Legal Services') && (
          <>
            {data.law_firm_sector_specialty.length !== 0 && (
              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.lawFirmSectorSpecialty')}</div>
                <div className="info">{convertOptions(data.law_firm_sector_specialty).sector}</div>
              </DetailWrapper>
            )}
            {data.practice_areas.length !== 0 && (
              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.lawFirmPracticeAreas')}</div>
                <div className="info">{convertOptions(data.practice_areas).practice}</div>
              </DetailWrapper>
            )}
          </>
        )}

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.languages')}</div>
          <div className="info">
            {data.acc_languages
              ? `${convertToCurrentLangData(data.acc_languages, 'language', lang)} ${
                  data.acc_languages_other ? `(${data.acc_languages_other})` : ''
                }`
              : '-'}
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.currency')}</div>
          <div className="info">
            {data.acc_currency
              ? `${convertToCurrentLangData(data.acc_currency, 'currency', lang)} ${
                  data.acc_currency_other ? `(${data.acc_currency_other})` : ''
                } `
              : '-'}
          </div>
        </DetailWrapper>
      </Flex>
      <Hr />
      <InfoTitle>{t('companyRepresentativeInformation')}</InfoTitle>
      <Flex flexDirection={'column'} gridGap={'15px'}>
        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.companyName')}</div>
          <div className="info">{data.acc_coname}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.title')}</div>
          <div className="info">{convertTitle(data.acc_title, lang)}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.giveName')}</div>
          <div className="info">{data?.acc_firstName}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.familyName')}</div>
          <div className="info">{data.acc_lastName}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.email')}</div>
          <div className="info">
            {/* 點擊後打開信箱 */}
            <a
              href={`mailto: ${data.email}`}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline', color: 'blue' }}
            >
              {data.email}
            </a>
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.jobPosition')}</div>
          <div className="info">{data.acc_jobPosition}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.region')}</div>
          <div className="info">
            {data.acc_country_other ? data.acc_country_other : convertToCurrentLangData(data.acc_country, 'name', lang)}
          </div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.timezone')}</div>
          <div className="info">{data.acc_timezone}</div>
        </DetailWrapper>

        <DetailWrapper isMobile={isMobile}>
          <div className="title">{t('info.mobilePhoneNumber')}</div>
          <div className="info">
            ({data.acc_countryCode}) {data.acc_mobile}
          </div>
        </DetailWrapper>
      </Flex>
      {/* 新增子公司資料顯示 */}
      {data.childCompanies &&
        Array.isArray(data.childCompanies) &&
        data.childCompanies.length !== 0 &&
        data.childCompanies.map((childCompany) => (
          <>
            <Hr />
            <InfoTitle>{t('companyRepresentativeInformation')}</InfoTitle>
            <Flex flexDirection={'column'} gridGap={'15px'}>
              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.companyName')}</div>
                <div className="info">{childCompany.acc_coname}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.title')}</div>
                <div className="info">{convertTitle(childCompany.acc_title, lang)}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.giveName')}</div>
                <div className="info">{childCompany?.acc_firstName}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.familyName')}</div>
                <div className="info">{childCompany.acc_lastName}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.email')}</div>
                <div className="info">
                  {/* 點擊後打開信箱 */}
                  <a
                    href={`mailto: ${childCompany.email}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline', color: 'blue' }}
                  >
                    {childCompany.email}
                  </a>
                </div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.jobPosition')}</div>
                <div className="info">{childCompany.acc_jobPosition}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.region')}</div>
                <div className="info">
                  {childCompany.acc_country_other
                    ? childCompany.acc_country_other
                    : convertToCurrentLangData(childCompany.acc_country, 'name', lang)}
                </div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.timezone')}</div>
                <div className="info">{childCompany.acc_timezone}</div>
              </DetailWrapper>

              <DetailWrapper isMobile={isMobile}>
                <div className="title">{t('info.mobilePhoneNumber')}</div>
                <div className="info">
                  ({childCompany.acc_countryCode}) {childCompany.acc_mobile}
                </div>
              </DetailWrapper>
            </Flex>
          </>
        ))}
    </InformationWrapper>
  );
};

interface ProfileProps {
  data: MemberProfile;
  showVerifiedBadge: boolean;
}

const Profile = ({ data, showVerifiedBadge }: ProfileProps) => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResize();
  const navigate = useNavigate();
  const { userData } = useAppSelector(getAuthSelector);

  const badgeGenerater = (badgeAry: Verification) => {
    const badges: React.ReactNode[] = [];
    // badgeAry.creditReport && badges.push(<CreditReportBadge key={'creditReport'} width={24} align={'center'} />);
    badgeAry.eCert && badges.push(<ECertBadge key={'eCert'} width={24} align={'center'} />);
    badgeAry.identification &&
      badges.push(<IdentificationCheckBadge key={'identification'} width={24} align={'center'} />);
    badgeAry.person && badges.push(<UserInfoBadge key={'person'} width={24} align={'center'} />);
    badgeAry.supplemental && badges.push(<SupplementalInfoBadge key={'supplemental'} width={24} align={'center'} />);
    showVerifiedBadge && badges.push(<AccountVerifiedBadge key={'accountVerified'} width={24} align={'center'} />);

    return badges;
  };

  const goToChat = async (user_id: number) => {
    if (!user_id) return;
    try {
      const res: ChatGenerateResponse = (await generateChatToUser({ user_id })).data;
      if (res.success) {
        navigate(`/chat?room=${res.data.id}`);
      }
    } catch (err) {}
  };

  return (
    <ProfileWrapper isMobile={isMobile} isTablet={isTablet}>
      <div className="logoBox">
        <img width={'100%'} height={100} style={{ objectFit: 'contain' }} src={data.acc_logo ?? '/images/default_company_logo.jpg'} alt="" />
      </div>
      <Flex marginTop={'15px'} gridGap={8}>
        {badgeGenerater(data.verification)}
      </Flex>
      {/* <Hr />
      <Flex alignItems={'center'}>
        {gradeGenerator(data.stars).map((item, index) => (
          <img width="33px" key={index} src={item} alt="grade" />
        ))}
        <Link to={'comments'}>{` (${data.rating_count})`}</Link>
      </Flex> */}
      <Hr />
      <Flex gridGap={10} marginTop={15} flexWrap={'wrap'}>
        {data?.id !== userData.id ? (
          <IconBtn onClick={() => goToChat(data?.id)} style={{ height: '44px', flex: isMobile ? '1' : 'unset' }}>
            <img src="/images/yellowpage/chat.svg" alt="" />
            {t('info.chat')}
          </IconBtn>
        ) : (
          <IconBtn className="disable">
            <img src="/images/yellowpage/chat.svg" alt="" />
          </IconBtn>
        )}
        <a href={`mailto: ${data.email}`} style={{ flex: isMobile ? '1' : 'unset' }}>
          <IconBtn style={{ width: isMobile ? '100%' : 'unset' }}>
            <img src="/images/yellowpage/mail-white.svg" alt="" />
            {t('info.email')}
          </IconBtn>
        </a>
        {/* <Link to={PATH.creditReport} style={{ width: isMobile ? '100%' : 'auto' }}>
          <Button
            width={isMobile ? '100%' : 'auto'}
            style={{ fontWeight: 400, padding: '0 10px', fontSize: theme.fontSizes.sm }}
            type={'button'}
            size={'half'}
            variant={'primary'}
          >
            {t('buttons.buyCreditReport')}
          </Button>
        </Link> */}
      </Flex>
    </ProfileWrapper>
  );
};

export default YellowPageDetail;
