import { axios } from '@/utils/axios';

export const getMeta = () => {
  return axios.get<Meta>('/api/frontData/meta');
}

export const getPosts = () => {
  return axios.get<BannerPost[]>('/api/frontData/post');
};

export const getFeatures = () => {
  return axios.get<BannerFeature[]>('/api/frontData/feature');
};

export const getFeatureDetail = (id: string) => {
  return axios.get<BannerFeature>(`/api/frontData/feature/${id}`);
};

export const getBlog = () => {
  return axios.get<Blog[]>('/api/frontData/blog');
}

export const getBlogDetail = (id: string) => {
  return axios.get<Blog>(`/api/frontData/blog/${id}`);
};

export const getFAQ = () => {
  return axios.get<FAQ[]>('/api/frontData/faq');
}

export const getFAQDetail = (id: string) => {
  return axios.get<FAQ>(`/api/frontData/faq/${id}`);
};