import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import { useSearchParams } from 'react-router-dom';
import Information from './Information';
import Stepline from './StepLine';
import Footer from '@/pages/Index/Footer';

const CartSuccessCreditReport = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(3);
  const stepline = t('pages.guest.creditReport.stepline', { returnObjects: true });
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));

  return (
    <>
      <PageContainer title={t('pages.guest.creditReport.title')}>
        <div style={{ display: 'flex' }}>
          <Stepline activeStep={activeStep} stepline={stepline} />
          <Information setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
        </div>
      </PageContainer>
      <Footer />
    </>
  );
};

export default CartSuccessCreditReport;
