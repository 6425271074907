import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@/redux/store.hook';
import { getAuthSelector } from '@/redux/auth/auth.slice';

import { Text } from '@/components/UI/Typography/Text';
import { Flex } from '@/layout';
import { ArrowRightSVG } from '@/components/Icon';
import ProgressItem from './ProgressItem';

import { Wrapper, StatusTag, ItemTitle, Participant } from './styles';
import { theme } from '@/theme/Theme';
import generateExecutionDetail from '@/utils/executionGenerator';
import { Button } from '@/components/UI/Button';
import { dealTagStatusHandler } from '@/utils/dealTagStatusHandler';

interface DealListItemProps {
  data: DealListItem;
}

const DealListItem = ({ data }: DealListItemProps) => {
  // @ts-ignore
  const { t } = useTranslation();

  const auth = useAppSelector(getAuthSelector);
  const { userData } = auth;

  const [executionDetail, setExecutionDetail] = useState<string[]>([]);
  const [executionPass, setExecutionPass] = useState<boolean[]>([]);
  const [closePass, setClosePass] = useState<boolean[]>([]);
  const [isOwner, setIsOwner] = useState(true);
  const [expiredDeal, setExpiredDeal] = useState(false);
  const dealRoleArray = t('dealRoleArray', { returnObjects: true });
  const [tagTitle, setTagTitle] = useState('');

  const status: { [key in typeof data.tag]: string } = {
    inviting: t('statusTags.inviting'),
    processing: t('statusTags.processing'),
    completed: t('statusTags.completed'),
    cancelled: t('statusTags.cancelled'),
  };
  const stage = t('dealStage', { returnObjects: true });
  const [isCancelled, setIsCancelled] = useState(false);

  const executionDataInit = (executionsData: ExecutionFile[]) => {
    const { executionAry, executionPassAry, closePassAry } = generateExecutionDetail(executionsData, 0);
    setExecutionDetail(executionAry);
    setExecutionPass(executionPassAry);
    setClosePass(closePassAry);
    checkExpired(data.expiry_date, data.progress);

    if (data.acc_id && data.acc_id.toString() !== userData.main_account_id.toString()) {
      setIsOwner(false);
    }
  };

  useEffect(() => {
    executionDataInit(data.executions);
  }, []);

  const checkExpired = (date: string | undefined, progress: string) => {
    if (progress === '1') {
      setTagTitle(status['processing']);
      return;
    }
    if (progress === '2') {
      setTagTitle(status['completed']);
      return;
    }
    if (progress === '3') {
      setTagTitle(status['cancelled']);
      setIsCancelled(true);
      return;
    }
    if (progress === '99') {
      setTagTitle(status['processing']);
      return;
    }
    if (!date) {
      setTagTitle(status['inviting']);
      return;
    }
    const now = new Date();
    const expired = new Date(date);
    if (now > expired) {
      setExpiredDeal(true);
      setTagTitle(t('statusTags.expired'));
    } else {
      setTagTitle(status['inviting']);
    }
  };

  return (
    <Wrapper>
      <Flex justifyContent={'space-between'} alignItems={'center'} padding={'22px 25px'}>
        <Flex gridGap={'15px'} alignItems={'center'}>
          <StatusTag variant={data.tag}>{tagTitle}</StatusTag>
          <ItemTitle>{data.tradeName}</ItemTitle>
        </Flex>
        <Flex gridGap={'15px'} alignItems={'center'}>
          {data.progress !== '0' && (
            <Link to={`/deal/${data.id}`}>
              <Button
                size={'table'}
                variant={'primary'}
                style={{ minWidth: 180, padding: '0 15px', fontWeight: 'normal' }}
              >
                <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                  {t('pages.deal.list.item.detail.goToDeal')}
                  <span style={{ transform: 'scale(0.8)', display: 'inline-flex' }}>
                    <ArrowRightSVG cursor={'pointer'} fill={theme.colors.light} />
                  </span>
                </Flex>
              </Button>
            </Link>
          )}

          {data.progress === '0' && (
            <Link to={`/deal/${data.id}/details`}>
              <Button
                size={'table'}
                variant={'primary'}
                style={{ minWidth: 180, padding: '0 15px', fontWeight: 'normal' }}
              >
                <Flex width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
                  {t('pages.deal.list.item.detail.goToDeal')}
                  <span style={{ transform: 'scale(0.8)', display: 'inline-flex' }}>
                    <ArrowRightSVG cursor={'pointer'} fill={theme.colors.light} />
                  </span>
                </Flex>
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
      <Flex gridGap={24}>
        <Flex width={260} flexDirection={'column'} gridGap={15}>
          {data.participants.map((p, i) => {
            if (p.deal_role_id === 0) {
              return (
                <Participant key={i}>
                  <Text fontSize={theme.fontSizes.md} className="title">
                    {dealRoleArray[Number(data.deal_type_id) - 1][0].text}
                  </Text>
                  <div className="name">
                    <Link to={`/companyDetail/${p.acc_id}`}>
                      <Text color={theme.colors.primary500}>{p.company_name}</Text>
                    </Link>
                  </div>
                </Participant>
              );
            }
          })}
          {data.participants.map((p, i) => {
              if (p.deal_role_id === 1) {
                return (
                  <Participant key={i}>
                    <Text fontSize={theme.fontSizes.md} className="title">
                      {dealRoleArray[Number(data.deal_type_id) - 1][1].text}
                    </Text>
                    <div className="name">
                      <Link to={`/companyDetail/${p.acc_id}`}>
                        <Text color={theme.colors.primary500}>{p.company_name}</Text>
                      </Link>
                    </div>
                  </Participant>
                );
              }
            })}
        </Flex>
        <Flex flex={'1 1 0'} overflow={'hidden'}>
          <ProgressItem
            itemName="step1"
            cancelled={isCancelled}
            name={stage.dealInitiation.name}
            isOwner={isOwner}
            detail={isOwner ? stage.dealInitiation[data.tag] : stage.dealInitiationForPartners[data.tag]}
            pass={data.dealInitiation}
            dealStatus={data.progress}
            id={data.id}
            expiredDeal={expiredDeal}
          />
          <ProgressItem
            itemName="step2"
            cancelled={isCancelled}
            name={stage.negotiation.name}
            isOwner={isOwner}
            detail={stage.negotiation[data.tag]}
            pass={data.negotiation}
            previousItem={data.dealInitiation}
            expiredDeal={expiredDeal}
          />
          <ProgressItem
            itemName="step3"
            cancelled={isCancelled}
            name={stage.execution.name}
            isOwner={isOwner}
            detail={stage.execution[data.tag]}
            pass={data.deal_close}
            previousItem={data.negotiation}
            expiredDeal={expiredDeal}
          />
          <ProgressItem
            itemName="step4"
            cancelled={isCancelled}
            name={stage.dealClosure.name}
            isOwner={isOwner}
            detail={stage.dealClosure[data.tag]}
            pass={data.contractExecution}
            previousItem={data.deal_close}
            expiredDeal={expiredDeal}
          />
        </Flex>
      </Flex>
    </Wrapper>
  );
};

export default DealListItem;
