import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '@/components/Container/PageContainer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Introduction from '@/components/Guest/CreditReport/Introduction';
import RegistrationForm from '@/components/Guest/CreditReport/RegistrationForm';
import Stepline from '@/components/ETrust/Stepline';
import Footer from '@/pages/Index/Footer';
import useCurrentLang from '@/hooks/useCurrentLang';

const GuestCreditReport = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<number>(0);
  const stepline = t('pages.guest.creditReport.stepline', { returnObjects: true });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(searchParams.get('step'));
  const lang = useCurrentLang();

  const [data, setData] = useState<CreditReportResponse>();

  useEffect(() => {
    if (!searchParams.get('step')) {
      setCurrentStep('1');
      navigate('/' + lang + '/guest/creditReport?step=1', { replace: true });
      setActiveStep(0);
    }

    if (!currentStep || parseInt(currentStep) === 0) {
      setCurrentStep('1');
      navigate('/' + lang + '/guest/creditReport?step=1');
      setActiveStep(0);
    } else {
      const paramsStep = parseInt(currentStep);
      setActiveStep(paramsStep - 1);
    }
  }, []);

  return (
    <>
      <PageContainer title={t('pages.guest.creditReport.title')}>
        <div style={{ display: 'flex' }}>
          <Stepline activeStep={activeStep} stepline={stepline} type={'CreditReport'} />
          {activeStep === 0 && (
            <Introduction setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
          )}
          {activeStep === 1 && (
            <RegistrationForm setActiveStep={setActiveStep} setCurrentStep={setCurrentStep} />
          )}
        </div>
      </PageContainer>
      <Footer />
    </>

  );
};

export default GuestCreditReport;
