import { useEffect, useState } from 'react';
import { getFeatures } from '@/api/frontData.api';

const useGetFeatures = () => {

  const [data, setData] = useState<BannerFeature[]>([]);

  const getFeaturesData = async () => {
    try {
      const features = (await getFeatures()).data;
      setData(features);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getFeaturesData();
  }, []);

  return data;
};

export default useGetFeatures;
