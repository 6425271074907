import Plans from '@/components/Plans';
import Footer from '../Footer';
import { ContainerWrapper, Title } from './style';
import { useTranslation } from 'react-i18next';
import useResize from '@/hooks/useResize';
import { Helmet } from 'react-helmet';

const Pricing = () => {
  // @ts-ignore
  const { t } = useTranslation();
  const { isMobile } = useResize();

  return (
    <>
      <Helmet>
        <title>{t('seo.pricing.title')}</title>
        <meta itemProp="name" content={t('seo.pricing.title')} />
        <meta property="og:title" content={t('seo.pricing.title')} />
        <meta name="twitter:title" content={t('seo.pricing.title')} />
        <meta name="description" content={t('seo.pricing.metaDescription')} />
        <meta property="og:description" content={t('seo.pricing.metaDescription')} />
        <meta name="twitter:description" content={t('seo.pricing.metaDescription')} />
        <link rel="canonical" href={`${process.env.REACT_APP_BASE_URL}/en/Subscription`} />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/en/Subscription`} hrefLang="en" />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/sc/Subscription`} hrefLang="zh-Hant" />
        <link rel="alternate" href={`${process.env.REACT_APP_BASE_URL}/tc/Subscription`} hrefLang="zh-Hans" />
      </Helmet>
      <ContainerWrapper>
        <Title isMobile={isMobile}>{t('pages.index.Pricing.title')}</Title>
        <Plans />
      </ContainerWrapper>
      <Footer />
    </>
  );
};

export default Pricing;
